.table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.table td {
  padding: 20px;
  font-size: 15px;
  border-bottom: 0.5px solid #ddd;
}
.table th {
  padding: 8px;
  font-size: 15px;
}

.table tr:nth-child(even) {
  background-color: #ddd;
}

.table tr:hover {
  background-color: #ddd;
}

.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 15px;
  text-align: left;
  height: 5vh;
  background-color: #45a29e;
  color: white;
}
