a {
  display: block;
  color: #fff;
  margin: 22px 0px;
  text-decoration: none;
  font-size: 18px;
  padding: 8px 8px 8px 22px;
}

a.active {
    background-color: #fff;
    color: #000;
}

a:first-child {
    margin-top: 0;
}

a:hover,
a:active {
  background-color: #fff;
  color: #000;
}