.body {
  background-color: black;
}
.drawerItems {
  background-color: gray;
  width: 200px;
  height: 91.5vh;
}
@media only screen and (min-width: 100px) and (max-width: 1200px) {
  .drawerItems {
    background-color: gray;
    width: 50px;
    height: 91.5vh;
  }
}
.mainDiv {
  height: 91.5vh;
  width: 100%;
  flex-direction: "row";
}
.child_div {
  float: left;
  /* margin-right: 5px; */
}
.div2 {
  float: left;
  width: 86.9%;
}
@media only screen and (min-width: 570px) and (max-width: 1500px) {
  .div2 {
    float: left;
    max-width: 83%;
  }
}
.textLabel {
  color: white;
  font-size: 18px;
  margin-bottom: 15px;
}
@media only screen and (min-width: 100px) and (max-width: 1200px) {
  .textLabel {
    visibility: hidden;
  }
}

.appBar {
  background: #2e3b55;
}
