.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: "100%";
  width: "100%";
  margin-top: 20px;
  flex-direction: column;
}

.Button {
  color: #d3d3d3;
}
